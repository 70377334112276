.selector-highlight-box{
	position: absolute;
	background: rgba(224, 1, 1, 0.2);
	border: 3px dashed rgba(224, 1, 1, 0.6);
	user-select: none;
}
.selector-highlight-box-active{
	position: absolute;
	background: rgba(255, 87, 0, 0.2);
	border: 1px dashed rgba(255, 87, 0, 0.8);
	user-select: none;
}
.selector-box-button{
	position: relative;
	top: -25px;
	left: 100%;
	font-size: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: 0;
	padding: 0;
	font-weight: bold;
	color: black;
	z-index: 200;
	user-select: none;
}
.selector-box-button:hover{
	color: red;
	cursor: pointer;
}
