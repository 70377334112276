.embedded-table{
	border: 1px solid #EFEFEF;
	border-collapse: collapse;
}
.embedded-table-table{
    border-collapse: collapse;
}
.excel-preview-format-1 {
	border-top: 2px solid black !important;
	background-color: #DEEBD6;
}
.excel-preview-format-2 {
	border-top: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-5 {
	border-top: 2px solid black !important;
	background-color: #DEEBD6;
}
.excel-preview-format-6 {
	border-top: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-10 {
	border-left: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-11 {
	border-top: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-21 {
	border-left: 2px solid black !important;
	background-color: #DEEBD6;
}
.excel-preview-format-22 {
	border-left: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-31 {
	border-top: 2px solid black !important;
	border-left: 2px solid black !important;
	background-color: #DEEBD6;
}
.excel-preview-format-32 {
	border-top: 2px solid black !important;
	border-left: 2px solid black !important;
	background-color: #F8CBAD;
}
.excel-preview-format-7 {
	border-top: 2px solid black !important;
	background-color: #FFFF00;
}
.excel-preview-format-8 {
	border-left: 2px solid black !important;
	background-color: #FFFF00;
}
.excel-preview-format-9 {
	border-top: 2px solid black !important;
	border-left: 2px solid black !important;
	background-color: #FFFF00;
}
