@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Roboto+Mono:400,700|Material+Icons+Extended');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400,500,700');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-weight: 300;
  line-height: 1.6;
}

/*
  Util CSS
*/
.root {
  position: relative;
  padding: 2.5rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.u-flex {
  display: flex;
}

.u-flex-col {
  display: flex;
  flex-direction: column;
}

.u-position-relative {
  position: relative;
}

.u-flex-stretch {
  align-items: stretch;
  flex-grow: 1;
}

.u-flex-end {
  justify-content: flex-end;
}

.u-margin-top-1 {
  margin-top: 0.5rem !important;
}

.u-margin-bottom-1 {
  margin-bottom: 0.5rem !important;
}

.u-margin-top-2 {
  margin-top: 1rem !important;
}

.u-margin-bottom-2 {
  margin-bottom: 1rem !important;
}

.u-margin-top-3 {
  margin-top: 1.5rem !important;
}

.u-margin-bottom-3 {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-4 {
  margin-top: 2rem !important;
}

.u-margin-bottom-4 {
  margin-bottom: 2rem !important;
}

/*
  loading mask
*/
.loading__container {
  position: absolute;

  /* dialog z-index = 1300, make this smaller to avoid blocking dialog */
  z-index: 1200;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading__ring {
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading__ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loading__ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading__ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading__ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
  Custom class CSS
*/
.iframe-wapper {
  width: 70vw;
  position: relative;
}

.iframe-container {
  overflow: hidden;
  padding-top: calc(100vh - 5.5rem - 3.52rem);
  position: relative;
}

.iframe-container iframe {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Add a background color to the top navigation */
.topnav {
  background-color: #126537;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  height: 5.5rem;
}

.topnav__logo-block {
  display: flex;
}

.topnav__logo-navlink {
  float: left;
  color: #f2f2f2;
  /* text-align: center; */
  padding: 1.2rem 0.8rem;
  text-decoration: none;
  font-size: 2rem;
}

.topnav__logo-icon-container {
  /* border: 1px solid orangered; */
  border-radius: 100px;
  width: 50px;
  height: 50px;
  padding: 7px 10px 7px 10px;
  background: white;
}

.topnav__logo-icon {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.topnav__avatar {
  margin: 0.8rem;
  text-transform: uppercase;
  background-color: #673ab7 !important;
  cursor: pointer;
}

/* Style the links inside the navigation bar */

/* .topnav__item {
  display: flex;
} */

.topnav__item a {
  /* float: left; */
  color: white;
  text-align: center;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 100;
  text-transform: none;
}

/* Change the color of links on hover */
.topnav a:hover,
.topnav__item a:hover {
  color: white;
}

/* Add a color to the active/current link */
.topnav a.active,
.topnav__item a.active {
  background-color: #126537;
  color: white;
}

.topnav__item a.is-active {
  background-color: #ddd;
  color: black;
  cursor: default;
}

.pdf-translation {
  display: flex;
  background-color: #474747;
  align-content: stretch;
  overflow: hidden;
}

.pdf-translation__header {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf-translation__header h2 {
  vertical-align: middle;
  line-height: 3.2rem;
  color: hsl(0, 0%, 85%);
}

.pdf-translation__header button {
  min-width: 16px;
  padding: 2px 6px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: hsla(0, 0%, 100%, 0.8);
  background-color: #474747;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4rem;
  user-select: none;
  cursor: default;
  margin: 0 0.8rem 0 0;
}

.pdf-translation__header button:hover {
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset,
    0 0 1px hsla(0, 0%, 100%, 0.15) inset, 0 0 1px hsla(0, 0%, 0%, 0.05);
  z-index: 199;
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0)
  );
  background-clip: padding-box;
  border: 1px solid hsla(0, 0%, 0%, 0.35);
  border-color: hsla(0, 0%, 0%, 0.32) hsla(0, 0%, 0%, 0.38)
    hsla(0, 0%, 0%, 0.42);
}

.pdf-translation__list {
  background-color: #474747;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8.7rem - 3.52rem);
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  animation: fadein 0.2s ease-in;
  padding: 2px;
}

.pdf-translation__list h3 {
  margin: 0 0 0 0.3rem;
  font-size: 1.3rem;
  color: hsl(0, 0%, 85%);
  padding: 1px;
}

.pdf-translation__item {
  display: block;
  margin: 2px auto;
  min-height: 5.2rem;
  align-items: stretch;
  width: 100%;
  resize: none;
  padding: 2px;
}

.pdf-translation__item--active,
.pdf-translation__item:focus {
  background-color: rgba(204, 224, 209);
}

.pdf-tranlation__toolbox {
  background-color: hsl(0, 0%, 28%);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8.7rem - 3.52rem);
  overflow: auto;
}

.pdf-tranlation__toolbox button {
  min-width: 16px;
  padding: 2px 6px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: hsla(0, 0%, 100%, 0.8);
  background-color: #474747;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  user-select: none;
  cursor: default;
  margin: 0 2px 2px 0;
}

.pdf-translation__ring {
  background-color: hsla(0, 0%, 100%, 0.3);
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0)
  );
  background-clip: padding-box;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset,
    0 0 1px hsla(0, 0%, 100%, 0.1) inset, 0 0 1px hsla(0, 0%, 0%, 0.2);
  color: hsla(0, 0%, 100%, 1);
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateX(150%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.handsontable {
  font-size: 1.4rem;
  font-family: 'Noto Sans TC', sans-serif;
}

.htContextMenu {
  z-index: 1500 !important;
}

#hot_termbasepdf {
  width: 400px;
  height: calc(100vh - 8.7rem - 3.52rem);
  overflow: hidden;
}

#hot_termbase {
  width: 100%;
  height: calc(100vh - 8.7rem - 3.52rem);
  overflow: hidden;
}

.Toastify__toast {
  font-size: 16px;
}

.Toastify__toast-container {
  width: 400px !important;
}

.dropdown__ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #5e5e5e;
}

.dropdown {
  float: left;
}

.dropdown a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 6px;
  text-decoration: none;
  font-size: 1.7rem;
}

.dropbtn-small {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 6px;
  text-decoration: none;
  font-size: 1.3rem;
}

.dropdown a:hover,
.dropdown:hover .dropbtn {
  background-color: #66bb6a;
}

.dropdown.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*
  Instant Translate CSS
*/

.instant-translation {
  height: calc(100vh - 8.7rem - 3.52rem);
}

.instant-translation__box {
  display: flex;
  align-items: stretch;
}

.instant-translation__box textarea {
  margin: 10px;
  flex: 1;
  font-size: 1.5rem;
  height: 15rem;
}

.instant-translation__form {
  display: flex;
  font-size: 1.5rem;
}

.instant-translation__form * {
  margin: 10.5px;
}

.instant-translation__control button {
  background-color: #126537;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.3rem 1.5rem;
  cursor: pointer;
  margin: 10px;
}

/*
  Compare result
*/
.compare-result {
  height: calc(100vh - 8.7rem - 3.52rem);
  display: flex;
  align-items: stretch;
}

.compare-result__original {
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.compare-result__compare {
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.compare-result__item {
  display: flex;
  flex-direction: column;
}

.compare-result__original .compare-result__item textarea {
  font-size: 1.5rem;
  height: 40rem;
}

.compare-result__compare .compare-result__item textarea {
  font-size: 1.5rem;
  height: 13.7rem;
}

.compare-result__control button {
  background-color: #126537;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 1rem 1rem;
  cursor: pointer;
  margin: 10px;
}

.compare-result__select{
  position : relative;
  left: 150px;
  bottom: 40px;
}

.compare-result__select select{
  position : relative;
  left : 50px;
  bottom: 25px;
  padding:5px;
}

.login {
  display: flex;

  /* flex-direction: column; */
  margin: 0 auto;
  background-image: linear-gradient(#126537, #3f87a6);
  height: 100vh;
}

.login__header {
  display: flex;
}

.login__form {
  border: 1px solid #dadce0;
  border-radius: 8px;

  /* width: 60rem; */
  margin: auto auto;
  padding: 3rem 3rem;
  background-color: #fff;
}

.login__form h1,
.login__form h2 {
  margin: 8px 10px;
}

.login__logo {
  width: 50px;
  margin: 0 40px 15px 10px;
}

.login__form h1 {
  font-size: 2.5rem;
}

.login__form h2 {
  font-size: 1.8rem;
}

.login__info {
  display: flex;
  flex-direction: column;
}

.login__warning {
  margin: 10px 10px;
  color: #86181d;
  font-size: 1.6rem;
  background-color: #ffdce0;
  border: 1px solid #ffdce0;
  padding: 15px 20px;
  border-radius: 8px;
}

.login__info label {
  margin: 0 10px;
  font-size: 1.6rem;
}

.login__info input,
.login__info button {
  margin: 2px 10px 15px 10px;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  padding: 0.5em 1em;
}

.login__info button:hover {
  cursor: pointer;
  background-color: #126537;
  border: 1px solid #126537;
  color: #fff;
}

.version {
  /* position: absolute;
  top: 0;
  right: 10px; */
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
